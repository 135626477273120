import { useModal } from 'vue-final-modal'

import HealthAspects from '~/components/modal-windows/HealthAspects.vue'

const localStorageKey = 'healthAspectsModalWindowClosedAt'
const localStorageOldKey = 'isHealthAspectsModalWindowClosed'
const delayAfterClosingInDays = 5
const delayBeforeShowingInMinutes = 2

export function useHealthAspectsModalWindow () {
  function getHealthAspectsModalWindowClosedAt (): number | null {
    // Migration.
    if (localStorage.getItem(localStorageOldKey) !== null) {
      localStorage.removeItem(localStorageOldKey)
      makeHealthAspectsModalWindowClosed()
    }

    const value = localStorage.getItem(localStorageKey) as string | null

    return value === null ? null : Number(value)
  }

  function makeHealthAspectsModalWindowClosed (): void {
    localStorage.setItem(localStorageKey, JSON.stringify(Date.now()))
  }

  function needToShowHealthAspectsModalWindow (): boolean {
    const healthAspectsModalWindowClosedAt = getHealthAspectsModalWindowClosedAt()

    return healthAspectsModalWindowClosedAt === null
      ? true
      : (Date.now() - healthAspectsModalWindowClosedAt) / (1000 * 3600 * 24) >= delayAfterClosingInDays
  }

  function showHealthAspectsModalWindowIfNeeded (): void {
    if (!needToShowHealthAspectsModalWindow()) {
      return
    }

    setTimeout(
      async function (): Promise<void> {
        if (!needToShowHealthAspectsModalWindow()) {
          return
        }

        await useModal({ component: HealthAspects }).open()
      },
      1000 * 60 * delayBeforeShowingInMinutes
    )
  }

  return {
    getHealthAspectsModalWindowClosedAt,
    makeHealthAspectsModalWindowClosed,
    showHealthAspectsModalWindowIfNeeded
  }
}
