<script lang="ts" setup>
import CardWithImage from '~/components/cards/CardWithImage.vue'
import CustomModalWindow from '~/components/modal-windows/CustomModalWindow.vue'
import { useHealthAspectsModalWindow } from '~/composables/health-aspects-modal-window'
import helpers from '~/helpers'
import JsonApiHelper from '~/helpers/json-api-helper'

const model = defineModel<boolean>()

const messages = { failedToGetTests: 'Не удалось получить тесты.' }
const page = { number: 1, size: 18 }

const { makeHealthAspectsModalWindowClosed } = useHealthAspectsModalWindow()
const { $apiHelper, $toast } = useNuxtApp()

const isTestsDataLoading = ref(false)
const isTestsDataLoadingError = ref(false)
const testsData = ref<any[]>([])

async function beforeOpen (): Promise<void> {
  await loadTestsData()
}

function close (): void {
  model.value = false
}

function closed (): void {
  makeHealthAspectsModalWindowClosed()
}

async function loadTestsData (): Promise<void> {
  isTestsDataLoading.value = true
  isTestsDataLoadingError.value = false

  const { number, size } = page
  const params = helpers.getParams([
    { name: 'page[number]', value: number, condition: true },
    { name: 'page[size]', value: size, condition: true }
  ])

  try {
    const response = await $apiHelper.tests.getTests(params) as any
    const { data } = JsonApiHelper.denormalizeResponse(response) as any
    testsData.value = data
  } catch (error) {
    console.error(error)

    isTestsDataLoadingError.value = true

    $toast.error(messages.failedToGetTests)
  }

  isTestsDataLoading.value = false
}
</script>

<template>
  <CustomModalWindow v-model="model" size="large" @before-open="beforeOpen" @closed="closed">
    <template #title>
      Индивидуально подберём витамины, подарим скидку&nbsp;15% после первого прохождения каждого теста и&nbsp;схему&nbsp;приёма
    </template>

    <Preloader v-if="isTestsDataLoading" />
    <Notification v-else-if="isTestsDataLoadingError" status="error">
      {{ messages.failedToGetTests }}
    </Notification>
    <div v-else-if="testsData.length" class="row" style="margin-bottom: -24px;">
      <div v-for="x in testsData" :key="x.id" class="column-4 mb24 min-2-columns tb12">
        <CardWithImage :data="x" :need-to-display-name="false" :path="helpers.getTestPath(x)" @click="close" />
      </div>
    </div>
    <Notification v-else status="info">
      Нет тестов.
    </Notification>

    <template v-if="!isTestsDataLoading" #action>
      <a v-if="isTestsDataLoadingError" href="#" @click.prevent="loadTestsData">Повторить попытку</a>
      <a v-else href="#" @click.prevent="close">Закрыть</a>
    </template>
  </CustomModalWindow>
</template>
